import './App.css';

import Button from './components/button';
import Header from './components/header';
import {Helmet} from "react-helmet";
import Palms from "./assets/palms.jpg"

function App() {
  return (
    <div className="App">
       <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="discoamore.de - let the music play." />
                <title>discoamore</title>
                <link rel="canonical" href="https://discoamore.de" />
            </Helmet>
      <Header title="DISCOAMORE" />
      <p>Discoamore @ Bandcamp</p>
      <Button text="Bandcamp" link="https://discoamore.bandcamp.com" />
      <img src={Palms} alt="discoamore Palms"  style={{width: "100%", height: "auto !important", marginTop: "40px"}} />
    </div>
  );
}

export default App;
