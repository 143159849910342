import "./button.css"

const Button = ({ text, link }) => {
    return (
        <a href={link} target="_blank" rel="noreferrer">
        <button className="button">{text}</button>
        </a>
    )
}

export default Button
