import "./header.css"

import Logo from "../assets/logo.png"

const Header = ({ title }) => {
    return (
        <div className="wrapper">
            <div className="logo">
                <img src={Logo} alt="discoamore logo" />
            </div>
            <div className="title">
                {title}
            </div>
        </div>
    )
}

export default Header